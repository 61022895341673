import { mapActions, mapState } from 'vuex';
import { showError } from '@/helpers/globalHelpers';

export default {
  name: 'SubtypeServiceDetail',

  // Propiedad 'data'
  data() {
    return {
      loading: false,
      loadingSave: false,
      selectedCostCenter: null,
      selectedCostCenters: [],
      selectedUser: null,
      selectedPairs: [],
      tableHeaders: [
        { text: 'Servicio', value: 'costCenterName', sortable: false },
        { text: 'Responsable', value: 'userName', sortable: false },
        { text: 'Acciones', value: 'action', sortable: false }
      ],
      actionType: "CREATE",
    };
  },

  created() {
    this.getClassificationById();
    this.getAllCostCenter();
    this.getAllUsers();
    this.listServiceSubtype();
  },

  beforeDestroy() {
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Inicio',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'Dashboard',
          },
        },
        {
          text: 'Permisos de Aplicación',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'PermissionsHome',
          },
        },
        {
          text: 'Servicio de subtipos',
          link: true,
          exact: true,
          disabled: false,
          to: {
            name: 'SubtypesServicePermissions',
          },
        },
        {
          text: 'Detalle de servicio de subtipos',
          link: true,
          exact: true,
          disabled: true,
          // to: {
          // 	name: 'SubtypesServicePermissions',
          // },
        },
      ];
    },
    ...mapState('classification',
      [
        'classification',
      ]
    ),
    ...mapState("security",
      [
        "usersActive",
        "costCenters",
      ]
    ),
    ...mapState("serviceSubtype",
      [
        "serviceSubtypes"
      ]),

    availableCostCenters() {
      return this.costCenters.filter(cc => !this.selectedPairs.some(pair => pair.service_id === cc.id));
    }
  },

  methods: {
    ...mapActions("security", [
      "getUsersActive",
      "getCostCenters",
    ]),
    ...mapActions('classification', ['listClassificationById']),
    ...mapActions('serviceSubtype', [
      'listServiceSubtypePagination',
      'createServiceSubtype',
      'editServiceSubtype'
    ]),

    async listServiceSubtype() {
      const classificationId = this.$route.params.classificationId;
      const { error } = await this.listServiceSubtypePagination({ classification_id: classificationId, pagination: false });
      if (error) showError(error);
      this.initializeFromExistingData();
    },

    initializeFromExistingData() {
      if (this.serviceSubtypes && this.serviceSubtypes.length > 0) {
        this.selectedPairs = this.serviceSubtypes.map(st => ({
          service_id: st.service.id,
          costCenterName: st.service.format_name,
          responsible_id: st.responsible.id,
          userName: st.responsible.name
        }));
        this.actionType = "EDIT";
      }
    },

    async getClassificationById() {
      const classificationId = this.$route.params.classificationId;
      this.loading = true;
      const { error } = await this.listClassificationById(classificationId);
      if (error) showError(error);
      this.loading = false;
    },

    addSelection() {
      if (this.selectedCostCenters.length === 0 || !this.selectedUser) {
        showError({ status: 422, data: { message: "Seleccione al menos un centro de costos y un responsable" } });
        return;
      }

      const existingIds = new Set(this.selectedPairs.map(pair => pair.service_id));
      let foundDuplicate = false;

      this.selectedCostCenters.forEach(selectedCostCenter => {
        if (existingIds.has(selectedCostCenter.id)) {
          foundDuplicate = true;
        } else {
          this.selectedPairs.push({
            service_id: selectedCostCenter.id,
            costCenterName: selectedCostCenter.format_name,
            responsible_id: this.selectedUser.id,
            userName: this.selectedUser.name
          });
          existingIds.add(selectedCostCenter.id);
        }
      });

      if (foundDuplicate) {
        showError({ status: 422, data: { message: "Uno o más centros de costos seleccionados ya han sido añadidos" } });
      }

      this.selectedCostCenters = [];
      this.selectedUser = null;
    },

    submitSelections() {
      let data;
      this.loadingSave = true;
      this.$swal({
        text: '¿Estás seguro de registar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0039a6',
        cancelButtonColor: 'grey',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sí',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const payload = {
            classification_id: this.classification.id,
            subtypes_service: this.selectedPairs.map(({ service_id, responsible_id }) => ({
              service_id,
              responsible_id
            }))
          };

          switch (this.actionType) {
            case 'EDIT':
              data = await this.editServiceSubtype(payload);
              break;
            case 'CREATE':
              data = await this.createServiceSubtype(payload);
              break;
          }

          if (data.error) showError(data.error);
          this.$router.push({ name: "SubtypesServicePermissions" });
        }
      })
    },

    removeSelection(item) {
      const index = this.selectedPairs.findIndex(pair => pair.service_id === item.service_id);
      if (index !== -1) {
        this.selectedPairs.splice(index, 1);
      }
    },

    async getAllCostCenter() {
      const { error } = await this.getCostCenters();
      if (error) showError(error);
    },

    async getAllUsers() {
      const { error } = await this.getUsersActive();
      if (error) showError(error);
    }
  },

  components: {
  },
};